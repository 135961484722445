<template>
  <div class="footer-broker-menus">
    <div class="container-broker-menu">
      <div class="footer-broker-menus-content">
        <div class="footer-broker-menus-content-header">
          <img src="../../assets/broker-menu/logo-mb.svg" alt="" />
        </div>

        <div class="footer-broker-menus-content-ul">
          <ul>
            <li><a href="">Privacy Policy</a></li>
            <li><a href="">Terms of use</a></li>
            <li><a href="">Cookie Policy</a></li>
          </ul>
        </div>

        <div class="footer-broker-menus-content-p">
          <p>
            This Website is controlled and operated by CNTRL-IT Ltd. (the
            “Operator”) and does not provide paid services to its users and the
            published information can be used free of charge. The ranking
            published in the Website is unbiased, objective and is based on
            publicly available information and objective, independent and
            impartial methodology. Although some of the information, materials
            and the ranking on the Website represent marketing information, it
            must be established that all of the content on the Website has been
            prepared through an independent and impartial methodology,
            independent from partnerships we have, for informational purposes
            and is intended for general use and is available as a service for
            the users’ convenience. The information, materials and the ranking
            contained on the Website do not entail any obligation to open any
            account with particular Investment broker or purchase investment
            services from it. The Operator does not receive any commissions from
            the listed Investment brokers for its activity performed on the
            Website and for the actions undertaken by the users. The
            distribution of the information, materials and the ranking contained
            on this Website are not to be construed as promotion of investment
            and/or ancillary services, or any other actions on behalf of any of
            the ranked Investment brokers. The Operator or this Website does not
            perform , receipt and transmission of instructions or orders in
            respect of investment services or financial instruments, placement
            of financial instruments or provision of advice to clients or
            prospective clients in respect to financial instruments or services.
            The information, materials and the ranking contained on the Website
            are not to be construed as: 1. an offer or solicitation for the
            purchase or sale of any financial instrument, or concluding any kind
            of transaction with financial instrument, or the provision of an
            offer to provide investment services or to trade with financial
            instruments; 2. investment advice or a personal recommendation in
            relation to transactions with financial instruments, or provision of
            advice in relation to the offered services by the ranked investment
            brokers. Information and the ranking contained on the Website are
            not under the scope of investment advisory or ancillary services.
            Although someone might assume that the information, materials and
            the ranking on the Website are marketing communication, it must be
            established that all of the content on the Website has been prepared
            solely for informational purposes and are intended for general use
            and are available only as a service for the users’ convenience. The
            Website does not guarantee the accuracy, timeliness, completeness or
            correct sequencing of the information, materials and the ranking, or
            warrant any results from users use or reliance on them. Any reliance
            on the materials and the information on the Website is at your own
            risk. Forex trading and trading with other financial instruments
            carries a high level of risk and may not be suitable for you. Before
            you engage in trading foreign exchange or other financial
            instruments, please make yourself acquainted with its specifics and
            all the risks associated with it. The Website and the Operator does
            not provide any investment advice, nor advise or recommend trading
            in general or with particular Investment broker. The Website and the
            Operator will not be performing any financial transactions on your
            behalf, nor are acting on behalf of any Investment broker, and will
            not be responsible for any loss or harm
          </p>
        </div>

        <div class="footer-broker-menus-content-rights">
          <p>BROKERS.MENU - 2020 © | All rights reserved.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.footer-broker-menus {
  background: #fff;
}
.footer-broker-menus-content {
  border-top: 1px solid #000;
  margin-top: 100px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.footer-broker-menus-content-header p {
  font-weight: 700;
}

.footer-broker-menus-content-p p {
  margin: 15px 0;
}

.footer-broker-menus-content-ul {
  margin-top: 15px;
}

.footer-broker-menus-content-ul ul {
  display: flex;
}
.footer-broker-menus-content-ul ul li {
  margin-right: 10px;
}
.footer-broker-menus-content-ul ul li a {
  color: #000;
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .footer-broker-menus-content {
    font-size: 12px !important;
  }

  .footer-broker-menus-content {
    margin-top: 50px;
  }
}
</style>